import React from 'react'
import { Button, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { saveOrderTypeByUser } from '../../state/orders-by-user/reducer'
import styled from 'styled-components'
import { FROM_AUD_TO_VN, FROM_VN_TO_AUD } from '../../contansts'
import { ordersByUserSelector } from '../../state/orders-by-user/reducer'
import { useSelector } from 'react-redux'
const CreateOrderModal = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderType } = useSelector(ordersByUserSelector)
  const confirmOrderType = (type) => {
    history.push(`/order/orderDetail/add?orderType=${type}`)
    dispatch(
      saveOrderTypeByUser({
        orderType: type,
      })
    )
    props.onCancel()
  }
  return (
    <div className='card'>
      <Modal {...props} title="Lựa chọn kiểu đơn hàng" footer={null}>
        <ModalBodyStyled>
          <Button type={orderType === FROM_AUD_TO_VN ? "primary" : ""} danger onClick={() => confirmOrderType(FROM_AUD_TO_VN)} style={{ marginRight: 10 }}>
            Vận chuyển từ Úc đến VN
          </Button>
          <Button type={orderType === FROM_VN_TO_AUD ? "primary" : ""} danger onClick={() => confirmOrderType(FROM_VN_TO_AUD)}>
            Vận chuyển từ VN đến Úc
          </Button>
        </ModalBodyStyled>
      </Modal>
    </div>
  )
}

const ModalBodyStyled = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`
export default CreateOrderModal
