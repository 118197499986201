export const BASE_API_URL = 'https://online-gate.epispost.com/api';

export const Endpoint = {
    GET_PROVINCE_AUD: `${BASE_API_URL}/lookup-zipcode`,
    LOGIN: `${BASE_API_URL}/auth/login`,
    GET_USER_INFOR: `${BASE_API_URL}/auth/user`,
    GET_CATEGORIES: `${BASE_API_URL}/categories`,
    GET_FEE_CALCULATION: `${BASE_API_URL}/fee-calculation`,
    GET_PROVINCE_VN: `${BASE_API_URL}/resource/provinces`,
    GET_DISTRIC_VN: `${BASE_API_URL}/resource/districts`,
    GET_WARD_VN: `${BASE_API_URL}/resource/wards`,
    GET_PAYMENT_TYPE: `${BASE_API_URL}/resource/payment-types`,
    CREATE_ORDER_VN_TO_AUD: `${BASE_API_URL}/orders`,
    REGISTER_VERIFY_PHONE_NUMBER: `${BASE_API_URL}/send-verify-code`,
    REGISTER_VERIFY_CODE: `${BASE_API_URL}/verify-phone-number`,
    REGISTER: `${BASE_API_URL}/register`,
    VERIFY_EMAIL: `${BASE_API_URL}/email/verify`,
    RESEND_EMAIL: `${BASE_API_URL}/email/resend`,
    SEND_REQUEST_FORGOT_PASSWORD: `${BASE_API_URL}/send-reset-email`,
    RESET_PASSWORD: `${BASE_API_URL}/reset-password`,
}