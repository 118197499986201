export const BASE_URL_API = 'https://api.vcs-vn.com'

export const MAX_ITEM_PAGE_ORDERS = 20

export const FROM_AUD_TO_VN = 0;
export const FROM_VN_TO_AUD = 1;

export const CURRENCY_TYPE_VND = 'VND';
export const CURRENCY_TYPE_AUD = 'AUD';
export const currencyType = [CURRENCY_TYPE_VND, CURRENCY_TYPE_AUD];

export const PROGRESS_PERSORNAL_INFOR = 0;
export const PROGRESS_PRODUCT_INFOR = 1;
export const PROGRESS_CONFIRM = 2;