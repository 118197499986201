import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector, logout } from '../state/auth/reducer'
import { Dropdown, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import LogoMini from '../assets/images/logo-epispost-mini.png'
import DefaultAvatar from '../assets/images/default-avatar.png'
import CreateOrderModal from '../views/order/CreateOrderModal'

export default function Navbar() {
  const { user } = useSelector(authSelector)
  const history = useHistory()
  const [visibleModalSelectOrderType, setVisibleModalSelectOrderType] = useState(false)
  const handleLogOut = () => {
    Cookies.remove('mem_epp_rsid')
    history.push('/login')
  }

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active')
  }

  const showModalSelectOrderType = () => {
    setVisibleModalSelectOrderType(!visibleModalSelectOrderType)
  }

  return (
    <nav className='navbar p-0 fixed-top d-flex flex-row'>
      <div className='navbar-menu-wrapper flex-grow d-flex align-items-stretch' style={{ height: 64, background: '#fff' }}>
        <ul className='navbar-nav navbar-nav-right'>
          <Dropdown alignRight as='li' className='nav-item border-left'>
            <Dropdown.Toggle as='span' className='nav-link count-indicator cursor-pointer'>
              <i className='mdi mdi-bell text-dark'></i>
              <span className='count bg-danger'></span>
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown alignRight as='li' className='nav-item d-flex align-items-center'>
            <Dropdown.Toggle as='span' className='nav-link cursor-pointer no-caret'>
              <div className='navbar-profile'>
                <img className='img-xs rounded-circle' src={user?.image || DefaultAvatar} alt='avatar' />
                <p className='mb-0 d-none d-sm-block navbar-profile-name'>
                  <span className='text-dark'>{user?.username || user?.full_name}</span>
                </p>
                <i className='mdi mdi-menu-down d-none d-sm-block'></i>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className='navbar-dropdown preview-list navbar-profile-dropdown-menu'>
              <h6 className='p-3 mb-0'>
                <span>Welcome</span>
              </h6>

              <Dropdown.Divider />

              <Dropdown.Item className='preview-item'>
                <div className='preview-thumbnail'>
                  <div className='preview-icon bg-secondary rounded-circle'>
                    <i className='mdi mdi-account text-success'></i>
                  </div>
                </div>
                <div className='preview-item-content'>
                  <p className='preview-subject mb-1'>
                    <Link to='/profile' variant='h6' className='text-dark'>
                      Tài khoản
                    </Link>
                  </p>
                </div>
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item className='preview-item'>
                <div className='preview-thumbnail'>
                  <div className='preview-icon bg-secondary rounded-circle'>
                    <i className='mdi mdi-settings text-danger'></i>
                  </div>
                </div>
                <div className='preview-item-content'>
                  <p className='preview-subject mb-1'>
                    <Link to='/settings' variant='h6' className='text-dark'>
                      Cài đặt
                    </Link>
                  </p>
                </div>
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item onClick={handleLogOut} className='preview-item'>
                <div className='preview-thumbnail'>
                  <div className='preview-icon bg-secondary rounded-circle'>
                    <i className='mdi mdi-logout text-danger'></i>
                  </div>
                </div>
                <div className='preview-item-content'>
                  <p className='preview-subject mb-1'>
                    <span>Đăng xuất</span>
                  </p>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
        <button
          className='navbar-toggler navbar-toggler-right d-lg-none align-self-center'
          type='button'
          onClick={toggleOffcanvas}
        >
          <span className='mdi mdi-format-line-spacing' />
        </button>
      </div>
    </nav>
  )
}
