import React from 'react'
import { Menu } from 'antd';
import {
  ShoppingCartOutlined,
  SearchOutlined,
  DollarCircleOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import './style.css'
import Logo from '../assets/images/logo-epispost.png'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
const routes = [
  {
    id: 1,
    title: 'Danh sách đơn hàng',
    icon: <ShoppingCartOutlined />,
    path: '/order'
  },
  {
    id: 2,
    title: 'Tra cứu đơn hàng',
    icon: <SearchOutlined />,
    path: '/searchOrder',
  },
  {
    id: 3,
    title: 'Chuyển tiền',
    icon: <DollarCircleOutlined />,
    path: '/transfer-money'
  },
  {
    id: 4,
    title: 'Cuộc gọi quốc tế',
    icon: <WhatsAppOutlined />,
    path: '/internationalCall'
  },
]


export default function NewSideBar() {
  const history = useHistory()
  const location = useLocation()
  const currentPath = location.pathname
  const goTo = (path) => {
    history.push(path)
  }

  const checkCurrenPath = (path) => {
    const findCurrentRoute = routes.find(route => route.path === path)
    if (findCurrentRoute) {
      return String(findCurrentRoute.id);
    }
    return '1'
  }
  return (
    <div>
      <div style={{ height: 64, display: 'flex', alignItems: 'center' }}>
        <a className='sidebar-brand brand-logo-mini' href='/home'>
          <img src={Logo} alt='logo' style={{ width: '100%' }} />
        </a>
      </div>
      <Menu theme="light" mode="inline" defaultSelectedKeys={[checkCurrenPath(currentPath)]} style={{ marginTop: 20 }}>
        {routes.map((item, index) => {
          return (
            <Menu.Item key={item.id} icon={item.icon} onClick={() => goTo(item.path)}>
              {item.title}
            </Menu.Item>
          )
        })}
      </Menu>
    </div>
  )
}
