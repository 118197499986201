import axios from 'axios';
import Cookies from 'js-cookie'
import { COOKIE_TOKEN_NAME } from '../utils/constant'
export const debounce = (fn, delay) => {
    console.log('xxx')
    console.log(delay)
    console.log(fn)
    let timer = null;
    return function (...args) {
        const context = this;
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}
export const setToken = async (token = '') => {
    console.log('token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const clearToken = async () => {
    axios.defaults.headers.common['Authorization'] = '';
};

const requestAbordCode = 'ECONNABORTED';

axios.defaults.baseURL = '';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.timeout = 5000;

const RequestClient = class {
    constructor() {
        this.init();
    }
    async init() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get(COOKIE_TOKEN_NAME)}`;
    }
    async headers(params) {
        let keys = Object.keys(params);
        keys.map((key) => {
            axios.defaults.headers.common[key] = params[key];
        });
    }

    async login(endpoint, bodyData) {
        let response = await fetch(endpoint, {
            method: 'POST',
            body: bodyData,
        });

        return response;
    }

    async get(endpoint, params = {}) {
        try {
            const response = await axios.get(endpoint, params);
            return response;
        } catch (error) {
            this.handleError(error);
        }
    }

    async post(endpoint, body: {}, params = {}) {
        try {
            const response = await axios.post(endpoint, body, params);
            return response;
        } catch (error) {
            this.handleError(error);
        }
    }

    async put(endpoint, body: {}, params = {}) {
        try {
            const response = await axios.put(endpoint, body, params);
            return response;
        } catch (error) {
            this.handleError(error);
        }
    }

    async delete(endpoint, body) {
        try {
            const response = await axios.delete(endpoint, { data: body });
            return response;
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error) {
        if (error.response && error.response.status === 401) {
        }
        if (error.code === requestAbordCode || ('response' in error && error.response === undefined)) {
            // delay(1000);
            error.recall = true;
        }
        throw error;
    }

    async postFormData(endpoint, body, params = {}) {
        try {
            const response = await axios.post(endpoint, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response;
        } catch (error) {
            this.handleError(error);
        }
    }
};

const client = new RequestClient();

export { client };
