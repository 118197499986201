import { takeEvery, put, all, call, fork } from 'redux-saga/effects'
import {
  login,
  loginSuccess,
  logout,
  forgotPassword,
  resetPassword,
  registerUser,
  verifyOTP,
  sendOTP,
} from './reducer'
import {
  resetPasswordRedux,
  forgotPasswordRedux,
  registerUserRedux,
  sendRegisterOTPRedux,
  verifyRegisterOTPRedux
} from './query'
import { message } from 'antd'
import fetchData from '../../utils'
import Cookies from 'js-cookie'
import { COOKIE_TOKEN_NAME } from '../../utils/constant'
import { client, setToken } from '../../api/client';
import { Endpoint } from '../../api/endpoint';




function watchLogout() {
  Cookies.remove(COOKIE_TOKEN_NAME)
  window.location.pathname = '/login'
}

function* watchForgotPassword({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'forgotPassword', forgotPasswordRedux, payload, false)

  if (callback) {
    callback(res)
  }
}

function* watchResetPassword({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'resetPassword', resetPasswordRedux, payload, false)

  if (callback) {
    callback(res)
  }
}

function* watchRegisterUser({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'registerUser', registerUserRedux, payload, false)

  if (callback) {
    callback(res)
  }
}

function* watchSendOTP({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'sendRegisterOTP', sendRegisterOTPRedux, payload)

  if (callback) {
    callback(res)
  }
}

function* watchVerifyOTP({ payload: { payload, callback } }) {
  const res = yield call(fetchData, 'verifyRegisterOTP', verifyRegisterOTPRedux, payload)

  if (callback) {
    callback(res)
  }
}

function* checkIsAuth() {
  try {
    const token = Cookies.get(COOKIE_TOKEN_NAME)
    if (!token) return
    const result = yield client.get(Endpoint.GET_USER_INFOR)
    if (result.status === 200) {
      yield put(loginSuccess(result.data));
    } else {
      window.location.pathname = '/login'
    }
  }
  catch (error) {
    if (error.response && error.response.data.message) {
      const msg = error.response.data.message
      message.error(`Có lỗi xảy ra: ${msg}`);
    }
  }
}

function* handleLogin(action) {
  const params = action.payload.values
  const setLoading = action.payload.setLoading
  try {
    const res = yield client.post(Endpoint.LOGIN, params);
    if (res.status === 200 && res.data.access_token) {
      setLoading(false)
      setToken(res.data.access_token)
      yield fork(checkIsAuth)
      Cookies.set('mem_epp_rsid', res.data.access_token, { expires: 7 })
      window.location.pathname = '/order'
    }
  }
  catch (error) {
    setLoading(false)
    if (error.response && error.response.data.message) {
      const msg = error.response.data.message
      message.error(`Có lỗi xảy ra: ${msg}`);
    }
  }

}

export const rootSagas = function* rootSagas() {
  yield all([
    fork(checkIsAuth),
    takeEvery(login.type, handleLogin),
    takeEvery(logout.type, watchLogout),
    takeEvery(forgotPassword.type, watchForgotPassword),
    takeEvery(resetPassword.type, watchResetPassword),
    takeEvery(registerUser.type, watchRegisterUser),
    takeEvery(sendOTP.type, watchSendOTP),
    takeEvery(verifyOTP.type, watchVerifyOTP)
  ])
}
