import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import Cookies from 'js-cookie'

import AppRoutes from './routes'
import Navbar from './layouts/Navbar'
import Sidebar from './layouts/Sidebar'
import FooterContainer from './layouts/Footer'
import "antd/dist/antd.css";
import store from '../src/state'
import i18n from './translation'
import { Layout } from 'antd';
import './App.scss'
import './assets/styles/app.css'
const { Header, Footer, Sider, Content } = Layout;

const App = () => {
  const isAuth = Cookies.get('mem_epp_rsid')
  const _renderNavbar = isAuth ? <Navbar /> : ''
  const [collapsed, setCollapsed] = useState(false)
  const toggle = () => {
    setCollapsed(!collapsed)
  }
  return (
    <Provider store={store}>
      <script src='https://cdn.jsdelivr.net/npm/algoliasearch@4.5.1/dist/algoliasearch-lite.umd.js' />
      <script src='https://cdn.jsdelivr.net/npm/algoliasearch@4.5.1/dist/algoliasearch.umd.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-app.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-analytics.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-auth.js' />
      <script src='https://www.gstatic.com/firebasejs/8.3.3/firebase-firestore.js' />
      <I18nextProvider i18n={i18n}>
        <Layout style={{ height: '100vh' }}>
          <Sider theme="light" collapsible collapsed={collapsed} onCollapse={toggle} >{<Sidebar />}</Sider>
          <Layout>
            <Header style={{ background: '#fff' }}>
              {_renderNavbar}
            </Header>
            <Content>
              <AppRoutes />
            </Content>
            <Footer style={{ padding: 0 }}><FooterContainer /></Footer>
          </Layout>
        </Layout>
      </I18nextProvider>
    </Provider>
  )
}

export default withTranslation()(withRouter(App))
