import { createSlice } from '@reduxjs/toolkit'
export const namespace = 'ordersByUser'

const initialState = {
  loading: true,
  error: null,
  data: [],
  count: 0,
  orderType: 0
}

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    saveOrderTypeByUser: (state, action) => ({
      ...state,
      ...action.payload
    }),
    getOrdersByUser: (state) => ({
      ...state
    }),
    getSuccess: (state, action) => ({
      ...state,
      error: null,
      loading: false,
      data: action.payload.data,
      count: action.payload.count
    }),
    getError: (state, action) => ({
      ...state,
      data: [],
      count: 0,
      loading: false,
      error: action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const { getOrdersByUser, getSuccess, getError, saveOrderTypeByUser } = slice.actions
// Selector
export const ordersByUserSelector = (state) => state[namespace]
// Reducer
export const { reducer } = slice

export { rootSagas as saga } from './saga'
