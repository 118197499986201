import { takeEvery, put, all } from 'redux-saga/effects'
import { getList, getListSuccess } from './reducer'
import { client } from '../../api/client';
import { Endpoint } from '../../api/endpoint';

function* watchGetList({ payload }) {
  const res = yield client.get(Endpoint.GET_PAYMENT_TYPE);
  if (res.status === 200) {
    yield put(getListSuccess(res.data.data));
  }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(getList.type, watchGetList)])
}
